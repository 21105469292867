import React, { useEffect, useState } from "react";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProductTitle } from "../../utils/common";
import generalGb from '../../assets/generalBackground.svg';

function Products(props) {
    const { t, i18n } = useTranslation();

    const [chosenBrand, setChosenBrand] = useState(null);
    const [chosenCategory, setChosenCategory] = useState(null);
    const [subCategory, setSubCategory] = useState(null);

    const [searchParams] = useSearchParams();

    const [chosenLang, setChosenLang] = useState('mk');

    const [isLoading, setIsLoading] = useState(false);

    const { categoryParam, subCategoryParam, brandParam } = useParams();
    useEffect(() => {
        const isCategorySet = categoryParam && categoryParam !== '0';

        setChosenCategory(isCategorySet ? categoryParam : null );

        if (isCategorySet) {
            setIsLoading(true);
        }

        setChosenBrand(brandParam ?? null);
        setSubCategory(subCategoryParam ?? null);
    
        if (searchParams.get('lang')) {
            setChosenLang(searchParams.get('lang'));
        }
        
        
        i18n.changeLanguage(searchParams.get('lang'));
    }, [setSubCategory, setChosenBrand, setChosenCategory, brandParam, subCategoryParam, categoryParam, searchParams, i18n]);

    const titles = [
        {
            id: 'Neurology',
            title: t('products.neurology'),
            image: props.websitePhotos.neurology,
        },
        {
            id: 'Internal Medicine',
            title: t('products.internalMedicine'),
            image: props.websitePhotos.internalMedicine
        },
        {
            id: 'Rehabilitation',
            title: t('products.rehabilitation'),
            image: props.websitePhotos.rehabilitation
        },
    ];

    const filterByBrand = (product) => {
        if (!chosenBrand) {
            return product;
        }

        return product.brand.toLowerCase() === chosenBrand.toLowerCase();
    } 

    const filterByCategory = (product) => {
        if (!chosenCategory) {
            return product;
        }

        return product.category.toLowerCase() === chosenCategory.toLowerCase();
    } 

    const [currentPage, setCurrentPage] = useState(1);

    const perPage = 8;

    const filteredData = props.data.filter((el) => {
        return (
            filterByBrand(el)
            && filterByCategory(el)
        ); 
    });

    const paginatedFilteredData = filteredData.slice(0, currentPage * perPage);

    const getTitle = () => {
        if (subCategory && chosenBrand) {
            return `${subCategory} - ${chosenBrand}`;
        }

        if (chosenCategory) {
            const title = titles.find(title => title.id === chosenCategory);

            return title.title;
        }

        return t('products.shopProducts');
    }
    
    const getImage = () => {
        const title = titles.find(title => title.id === chosenCategory);

        return title.image;
    }

    const getCategoryBanner = () => {
        if (chosenCategory) {
            return (
                <div
                    className="header-banner pb-2 mb-5"
                >
                    <img src={getImage()} alt="" loading="lazy" onLoad={() => setIsLoading(false)}/>
                    <div className="header-banner-title">
                        <h2>{ getTitle() }</h2>
                    </div>
                </div>
            )
        }

        return (
            <div className="header-title">
                <h2>{getTitle()}</h2>
            </div>
        )
    }


    if (isLoading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="loading-state">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                        {
                            chosenCategory ?
                                <img src={getImage()} alt="" loading="lazy" onLoad={() => setIsLoading(false)} style={{ opacity: 0 }}/>
                            : ''
                        }
                    </div>
                </div>
            </div>            
        )
    }
    return (
        <React.Fragment>
            <div
                className='shop-products-main-wrapper mb-5'
                style={{ background: `url(${generalGb})`, backgroundSize: `${paginatedFilteredData.length < 3  ? '300vh' : paginatedFilteredData.length * 50}vh`}}
            >
                <div className='row'>
                    <div className="col-lg-12">
                        { getCategoryBanner() }
                    </div>
                    <div className='col-lg-12'>
                        <div className='shop-box mb-5'>
                            <div className='shop-products container '>
                                { paginatedFilteredData.map((product, index) => {
                                    return (
                                        <NavLink to={`/product/${product.id}?lang=${chosenLang}`} key={index} onClick={() => { window.scrollTo(0, 0)}}>
                                            <div className='shop-product-box' >
                                                <div className='shop-product-image'>
                                                    <img src={product.image} alt='' />
                                                </div>
                                                <div className='shop-product-data'>
                                                    <h3>{getProductTitle(product)}</h3>
                                                </div>
                                            </div>
                                        </NavLink>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="shop-pagination text-center">
                            { paginatedFilteredData.length < filteredData.length ?
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => { setCurrentPage(currentPage + 1) }}
                                >
                                    { t('products.load') }
                                </button>
                            : '' }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Products;